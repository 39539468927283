import { BaseService } from "./base.service";
import { ErrorWrapper, ResponseWrapper } from "./util";

export class OnboardingService extends BaseService {
  static async getDoctorOnboarding() {
    try {
      const response = await this.request({ auth: true }).get(
        "/doctor/onboarding"
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async updateDoctorOnboarding(step, data, config = {}) {
    try {
      const response = await this.request({ auth: true }).put(
        `/doctor/onboarding/step/${step}`,
        data,
        config
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async lookUp(tag) {
    try {
      const response = await this.request({ auth: true }).get(`lookup/${tag}`);
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
}
