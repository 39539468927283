<template>
  <b-card id="account">
    <template v-slot:header>
      <b-row align-v="center">
        <b-col>
          <h3 class="mb-0 bold">Account Details</h3>
        </b-col>
        <b-col cols="auto">
          <b-button
            pill
            variant="primary"
            :disabled="processing"
            v-on:click="updateUser()"
          >
            {{ processing ? "Saving..." : "Save Changes" }}
          </b-button>
        </b-col>
      </b-row>
    </template>

    <b-row>
      <b-col cols="8">
        <b-alert :show="alert.show" :variant="alert.type">{{
          alert.message
        }}</b-alert>
      </b-col>

      <b-col cols="12">
        <h4 class="mb-5 bold">Personal Details</h4>
      </b-col>

      <b-col cols="12" sm="6" md="6" class="mb-4">
        <div role="group">
          <label for="firstName">First Name:</label>
          <b-form-input
            id="firstName"
            class="pl-0"
            type="text"
            :disabled="processing"
            v-model="firstName"
          ></b-form-input>
        </div>
      </b-col>

      <b-col cols="12" sm="6" md="6" class="mb-4">
        <div role="group">
          <label for="lastName">Last Name:</label>
          <b-form-input
            id="lastName"
            class="pl-0"
            type="text"
            :disabled="processing"
            v-model="lastName"
          ></b-form-input>
        </div>
      </b-col>

      <b-col cols="12" sm="6" md="6" class="mb-4">
        <div role="group">
          <label for="birthday">Birthday:</label>
          <flat-pickr
            :config="config"
            class="form-control pl-0"
            :disabled="processing"
            v-model="birthday"
            id="birthday"
          ></flat-pickr>
        </div>
      </b-col>

      <b-col cols="12" sm="6" md="6" class="mb-4">
        <div role="group">
          <label for="gender">Gender:</label>
          <v-select
            id="gender"
            append-to-body
            :calculate-position="withPopper"
            :disabled="processing"
            v-model="gender"
            label="text"
            :options="options"
          >
            <template #open-indicator="{ attributes }">
              <b-icon
                v-bind="attributes"
                icon="chevron-down"
                variant="primary"
              ></b-icon>
            </template>
          </v-select>
        </div>
      </b-col>

      <b-col cols="12" sm="6" md="6" class="mb-4">
        <div role="group">
          <label for="input-live">Email Address:</label>
          <b-form-input
            id="email"
            class="pl-0"
            type="email"
            v-model="email"
            readonly
          ></b-form-input>
        </div>
      </b-col>

      <b-col cols="12" sm="6" md="6" class="mb-4">
        <div role="group">
          <label for="phone">Phone:</label>
          <b-form-input
            id="phone"
            class="pl-0"
            type="text"
            :disabled="processing"
            v-model="phone"
          ></b-form-input>

          <b-form-invalid-feedback id="input-live-feedback">
            Invalid phone number format
          </b-form-invalid-feedback>
        </div>
      </b-col>

      <!-- State License -->
      <b-col cols="12" sm="6" md="6" class="mb-4">
        <div role="group">
          <label for="stateLicense">State License: </label>
          <v-select
            id="stateLicense"
            append-to-body
            :calculate-position="withPopper"
            :disabled="processing"
            v-model="stateLicense"
            label="name"
            :options="license"
            :reduce="(license) => license.id"
          >
            <template #open-indicator="{ attributes }">
              <b-icon
                v-bind="attributes"
                icon="chevron-down"
                variant="primary"
              ></b-icon>
            </template>
          </v-select>
        </div>
      </b-col>

      <b-col cols="12" sm="6" md="6" class="mb-4">
        <div role="group">
          <label for="input-live">Fax:</label>
          <b-form-input
            id="faxno"
            class="pl-0"
            type="text"
            :disabled="processing"
            v-model="faxno"
          ></b-form-input>
        </div>
      </b-col>

      <b-col cols="12" sm="6" md="6" class="mb-6">
        <div role="group">
          <label for="input-live">NPI Number:</label>
          <b-form-input
            id="npinumber"
            class="pl-0"
            :disabled="processing"
            type="text"
            v-model="npinumber"
          ></b-form-input>
        </div>
      </b-col>

      <b-col cols="12" sm="6" md="6"> <!-- serve as space --> </b-col>

      <b-col cols="12" sm="6" md="6" class="mb-4">
        <div role="group">
          <label for="input-live">Street Address:</label>
          <b-form-input
            id="address1"
            class="pl-0"
            :disabled="processing"
            type="text"
            v-model="address1"
          ></b-form-input>
        </div>
      </b-col>

      <b-col cols="12" sm="6" md="6" class="mb-4">
        <div role="group">
          <label for="input-live">Apartment/Suite:</label>
          <b-form-input
            id="address 2"
            class="pl-0"
            :disabled="processing"
            type="text"
            v-model="address2"
          ></b-form-input>
        </div>
      </b-col>

      <b-col cols="12" sm="6" md="6" class="mb-4">
        <div role="group">
          <label for="input-live">City:</label>
          <b-form-input
            id="city"
            class="pl-0"
            type="text"
            :disabled="processing"
            v-model="city"
          ></b-form-input>
        </div>
      </b-col>

      <b-col cols="12" sm="6" md="6" class="mb-4">
        <div role="group">
          <label for="state">State:</label>
          <v-select
            id="state"
            append-to-body
            :calculate-position="withPopper"
            :disabled="processing"
            v-model="state"
            label="name"
            :options="stateOptions"
            :reduce="(stateOptions) => stateOptions.id"
          >
            <template #open-indicator="{ attributes }">
              <b-icon
                v-bind="attributes"
                icon="chevron-down"
                variant="primary"
              ></b-icon>
            </template>
          </v-select>
        </div>
      </b-col>

      <b-col cols="12" sm="6" md="6" class="mb-4">
        <div role="group">
          <label for="input-live">Zipcode:</label>
          <b-form-input
            id="zipcode"
            class="pl-0"
            :disabled="processing"
            type="text"
            v-model="zipcode"
          ></b-form-input>
        </div>
      </b-col>

      <b-col cols="12" class="mt-5">
        <b-row align-v="end" no-gutters>
          <b-col cols="auto">
            <h3 class="mb-1">Profile Image</h3>
            <p class="text-muted">PNG or JPG. Maximum file size is 5MB.</p>
            <div
              :disabled="processing"
              @click="$refs.file.click()"
              ref="preview"
              id="preview"
              style="overflow: hidden"
              class="avatar avatar-preview bg-primary rounded-circle"
            >
              <img
                v-if="showPreview"
                :src="imagePreview"
                class="avatar-img previewOnly"
              />
              <template v-else>
                <img
                  v-if="avatarImg"
                  :src="avatarImg"
                  class="avatar-img recent"
                />
              </template>
            </div>
            <!-- <div @click="removeAvatar()" class="remove-avatar">
              <b-avatar variant="primary" icon="x" size="20px"></b-avatar>
            </div> -->
          </b-col>
          <b-col>
            <b-button
              pill
              size="md"
              :disabled="processing"
              variant="outline-primary"
              class="mt-3 mb-4 photo-btn"
            >
              <input
                type="file"
                id="file"
                :disabled="processing"
                ref="file"
                accept="image/*"
                class="hide-file-input"
                v-on:change="handleFileUpload()"
              />
              <label for="file" class="mb-0">BROWSE PHOTOS</label>
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import popper from "@/assets/js/popper.js";
import { UsersService } from "@/services/users.service";
import { OnboardingService } from "@/services/onboarding.service";

export default {
  name: "account",
  mixins: [popper],
  data() {
    return {
      config: {
        disableMobile: true,
        altInput: true,
        altFormat: "m-d-Y",
        allowInput: true,
        minDate: this.moment().subtract(100, "year").format("YYYY-MM-DD"),
        maxDate: this.moment().subtract(18, "year").format("YYYY-MM-DD"),
      },
      license: [],
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      birthday: "",
      gender: null,
      npinumber: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      stateLicense: "",
      zipcode: "",
      faxno: "",
      processing: false,
      alert: {
        show: false,
        type: "info",
        message: "",
      },
      stateOptions: [],
      options: [
        { value: "Male", text: "Male" },
        { value: "Female", text: "Female" },
      ],
      file: "",
      imagePreview: "",
      showPreview: false,
      avatarImg: null,
    };
  },
  methods: {
    alertMessage(type, message) {
      let element = document
        .getElementById("account")
        .getElementsByClassName("card-body")[0];
      element.scrollTo(0, 0);

      this.alert = {
        type: type,
        message: message,
        show: true,
      };
    },
    removeAvatar() {
      this.avatarImg = null;
      this.imagePreview = "";
    },
    async updateUser() {
      try {
        this.processing = true;
        this.alert.show = false;
        // insert personal details axios here
        let data = {
          firstname: this.firstName,
          lastname: this.lastName,
          phoneno: this.phone,
          birthday: this.moment(this.birthday).format("YYYY-MM-DD"),
          gender: this.gender,
          address1: this.address1,
          address2: this.address2,
          state: this.state,
          licensestate: this.stateLicense,
          zipcode: this.zipcode,
          city: this.city,
          npinumber: this.npinumber,
          faxno: this.faxno,
        };

        console.log(data);

        const { data: auth } = await UsersService.updateUser(data);
        if (this.showPreview == true || this.avatarImg == null) {
          let formData = new FormData();
          formData.append("avatar", this.file);
          await UsersService.uploadAvatar(formData);
        }
        this.$store.dispatch("user/getUser");
        this.alertMessage("success", "Account Details Updated.");
      } catch (error) {
        this.error =
          error.status === 404
            ? "User with same email not found"
            : error.message;
        this.alertMessage("warning", this.error);
      } finally {
        this.processing = false;
      }
    },
    async listState() {
      try {
        let states = await OnboardingService.lookUp("state");
        this.stateOptions = states.data.filter(function (o) {
          return o.supported == true;
        });
        this.license = states.data;
      } catch (error) {
        console.log(error);
      }
    },
    getUser() {
      this.$store
        .dispatch("user/getUser")
        .then((data) => {
          this.firstName = data.firstname;
          this.lastName = data.lastname;
          this.email = data.email;
          this.phone = data.phoneno;
          this.address1 = data.address1;
          this.address2 = data.address2;
          this.state = data.state;
          this.stateLicense = data.licensestate;
          this.city = data.city;
          this.zipcode = data.zipcode;
          this.npinumber = data.npinumber;
          this.faxno = data.faxno;

          (this.birthday = data.birthday), (this.avatarImg = data.profileUrl);

          if (data.gender) {
            this.gender = data.gender;
          }
        })
        .catch((error) => {
          if (error.message) {
            return this.alertMessage("warning", error.message);
          }
        });
    },
    handleFileUpload() {
      const maxAllowedSize = 5 * 1024 * 1024;
      //Set the local file variable to what the user has selected.
      this.file = this.$refs.file.files[0];
      if (this.file.size > maxAllowedSize) {
        return this.alertMessage("warning", "Avatar should not exceed 5MB");
      }
      // Initialize a File Reader object
      let reader = new FileReader();
      // Add an event listener to the reader that when the file
      // has been loaded, we flag the show preview as true and set the
      // image to be what was read from the reader
      reader.addEventListener(
        "load",
        function () {
          this.showPreview = true;
          this.imagePreview = reader.result;
        }.bind(this),
        false
      );
      // Check to see if the file is not empty.
      if (this.file) {
        //  Ensure the file is an image file.
        if (/\.(jpe?g|png)$/i.test(this.file.name)) {
          // Fire the readAsDataURL method which will read the file in and
          // upon completion fire a 'load' event which we will listen to and
          // display the image in the preview.
          reader.readAsDataURL(this.file);
        }
      }
    },
  },
  mounted() {
    this.getUser();
    this.listState();
  },
};
</script>

<style lang="scss" scoped>
label {
  color: #4f4f4f !important;
  font-size: 11px;

  // @include media-breakpoint-up(xxl) {
  //   font-size: (12px * 2) !important;
  // }
}

.card-header {
  border-bottom: 0;
  padding: 3rem 1.5rem;
}

.card-body {
  height: calc(100vh - 260px);
  overflow: auto;

  // @include media-breakpoint-up(xxl) {
  //   height: calc(100vh - 22vh);
  // }
}

@media (max-width: 768px) {
  .card-body {
    height: auto;
  }
}

.remove-avatar {
  position: relative;
  top: -8px;
  margin-left: -15px;
  display: inline;
}

.avatar-preview {
  width: 9rem;
  height: 9rem;
}
</style>
